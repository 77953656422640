import React from 'react'
import BasePortableText from '@sanity/block-content-to-react'
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'
import clientConfig from '../../../client-config'
import { Link } from './Link'
import { theme } from './Theme'
import { Figure } from './Figure'
import { BlogBuilderForm } from '../Builder/BlogBuilderForm'
import styled, { css } from 'styled-components'
import { rgba } from './ColorFunctions'

const tableStyle = {
  tableStyle: 'align-left uppercase text-grey text-left',
  tableRowStyle: 'text-9 text-grey-dark text-20 leading-tight',
  'tableRowStyle-0': '!text-12 !text-black uppercase font-medium tracking-wide',
  tableCellStyle: 'border border-grey-dark p-15 uppercase',
}

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  background-color: #fff;
  color: black;
  table-layout: fixed;
`

const TableRow = styled.tr`
  &:nth-child(1) {
    background-color: ${(props) =>
      props.highlightFirstRow ? '#f2f2f2' : 'transparent'};
    font-weight: ${(props) =>
      props.highlightFirstRow ? 'bold !important' : 'normal'};
  }

  & td:first-child {
    background-color: ${(props) =>
      props.highlightFirstColumn ? '#f2f2f2' : 'transparent'};
    font-weight: ${(props) =>
      props.highlightFirstColumn ? 'bold !important' : 'normal'};
    width: 100%;
    max-width: 25%;
  }
`

const TableCell = styled.td`
  flex-shrink: 0;
  padding: 8px;
  border: 1px solid #dee2e6;
  text-align: left;
  color: ${({ theme }) => theme.primary};
  *,
  a {
    font-size: 16px !important;
  }
`

const Table = (table) => {
  return (
    <StyledTable>
      <tbody>
        {table.node.rows.map((row, i) => (
          <TableRow
            key={row._key}
            highlightFirstRow={table.node.highlightFirstRow}
            highlightFirstColumn={table.node.highlightFirstColumn}
          >
            {row.cells.map((cell, j) => (
              <TableCell key={`${i}-${j}`}>
                <PortableText blocks={cell.content} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </tbody>
    </StyledTable>
  )
}

const ToC = styled.nav`
  width: 100%;
  padding: 1rem;
  margin-block: 2rem;
  border: 1px solid ${({ theme }) => rgba(theme.primary, 0.25)};

  ul {
    margin-block: 0 !important;
  }

  h2 {
    font-size: 1.5rem;
    margin: 0 0 1rem 0 !important;
  }
  li,
  a {
    font-size: 1.25rem !important;
  }

  li {
    margin-bottom: 0.25rem;
  }

  ul {
    margin-bottom: 0;
  }
`

const TableOfContents = ({ blocks }) => {
  const headers = blocks
    .filter((block) => block.style && /^h[1-3]$/.test(block.style))
    .map((block) => ({
      text: block.children.map((child) => child.text).join(' '),
      level: parseInt(block.style.replace('h', ''), 10),
      id: block._key,
    }))
  return (
    <ToC>
      <h2>Inhaltsverzeichnis</h2>
      <ul>
        {headers.map(({ text, level, id }) => (
          <li key={id} style={{ marginLeft: `${(level - 1) * 10}px` }}>
            <Link to={`#${id}`}>{text}</Link>
          </li>
        ))}
      </ul>
    </ToC>
  )
}

const getSerializers = (blocks) => {
  return {
    marks: {
      link: (props) => (
        <Link
          to={props.mark.href}
          style={{ fontFamily: theme.font.secondary }}
          isInline
          rel={props.mark.rel}
        >
          {props.children}
        </Link>
      ),
    },
    types: {
      block: (props) => {
        const { node, children } = props
        const style = node.style || 'normal'
        if (/^h\d/.test(style)) {
          const HeadingTag = style
          return (
            <HeadingTag id={node._key}>
              {children} <a href={`#${node._key}`}></a>
            </HeadingTag>
          )
        }
        return BasePortableText.defaultSerializers.types.block(props)
      },
      tableOfContents: () => <TableOfContents blocks={blocks} />,
      authorReference: ({ node }) => <span>{node.author.name}</span>,
      mainImage: Figure,
      youtube: ({ node }) => {
        const { url } = node
        const id = getYouTubeId(url)
        return <YouTube containerClassName="blogpostYoutube" videoId={id} />
      },
      table: Table,
      builder: ({ node }) => {
        const { baseBudget } = node

        return (
          <BlogBuilderForm
            baseBudget={{
              label: `${baseBudget}€`,
              value: parseInt(baseBudget),
            }}
          />
        )
      },
    },
  }
}

export const PortableText = ({ blocks, className }) => {
  const serializers = getSerializers(blocks)
  return (
    <BasePortableText
      blocks={blocks}
      serializers={serializers}
      className={className}
      {...clientConfig.sanity}
    />
  )
}
